<template>
<div>
  <div class="w-full p-3 flex flex-row justify-between items-center text-new-black font-visby" :class="{'border-b border-new-yellow' : border, 'opacity-50' : greyed}">
    <div v-if="showicon" class="w-10 flex-shrink-0">
      <kurseSVG v-if="icon === 'kurse'" />
      <faecherSVG v-if="icon === 'faecher'" />
      <tagSVG v-if="icon === 'tag'" />
      <timeSVG v-if="icon === 'time'" />
      <chatSVG v-if="icon === 'chat'" />
      <textSVG v-if="icon === 'text'" />
      <hatSVG v-if="icon === 'hat'" />
      <examSVG v-if="icon === 'exam'" />
      <listSVG v-if="icon === 'list'" />
      <usersSVG v-if="icon === 'battle'" />
      <lightningSVG v-if="icon === 'lightning'" />
      <adjustmentSVG v-if="icon === 'adjustment'" />
    </div>
    <div class="flex-grow pr-2 max-w-80">
      <div class="text-lg">{{label}}</div>
      <div v-if="additionalText" class="text-xs text-new-darkGrey w-11/12" :class="{'truncate' : truncate}">{{additionalText}}</div>
    </div>
    <chevronSVG v-if="action === 'chevron'" />
    <div v-if="action === 'toggle'" class="transition ease-in-out w-12 h-8 flex-shrink-0 bg-gray-200 rounded-full flex flex-row justify-start items-center overflow-hidden" :class="{'transition ease-in-out bg-gray-600' : switched}" @click="switchToggle">
      <div id="switch" class="transition ease-in-out bg-white w-7 h-7 ml-0.5 rounded-full shadow-md select-none pointer-events-none" :class="{'transition ease-in-out transform translate-x-4' : switched}"></div>
    </div>
  </div>
  <div v-if="additionalAcion === 'input' && switched" class="w-full p-3 flex flex-row items-center font-visby" :class="{'border-b border-new-yellow' : border}">
    <replySVG class="pr-2 w-10 flex-shrink-0" />
    <div class="text-lg">{{addActionLabel}}</div>
    <input v-model="additionalInput" type="number" class="appearance-none outline-none text-lg ml-1 w-auto caret-black accent-black text-new-black"/>
  </div>

  <div v-if="additionalAcion === 'choose' && switched" class="w-full flex flex-row items-center font-visby" :class="{'border-b border-new-yellow' : border}">
    <div class="transition ease-in-out w-1/3 p-3 text-center" :class="{'transition ease-in-out bg-new-black text-white' : additionalInput == 1 }" @click="additionalInput = 1">1 min</div>
    <div class="transition ease-in-out w-1/3 p-3 text-center" :class="{'transition ease-in-out bg-new-black text-white' : additionalInput == 1.5 }" @click="additionalInput = 1.5">1,5 min</div>
    <div class="transition ease-in-out w-1/3 p-3 text-center" :class="{'transition ease-in-out bg-new-black text-white' : additionalInput == 2 }" @click="additionalInput = 2">2 min</div>
  </div>

  <div v-if="additionalAcion === 'numInput' && switched" class="w-full flex flex-row items-center font-visby" :class="{'border-b border-new-yellow' : border, 'opacity-50 pointer-events-none' : greyed}">
    <div class="w-1/3 p-3 text-center flex flex-row justify-center items-center text-new-black  text-xl " @click="additionalInput = additionalInput - 4">-</div>
    <div class="w-1/3 p-3 text-center">{{ !loadedValue ? additionalInput : loadedValue }} Fragen</div>
    <div class="w-1/3 p-3 text-center flex flex-row justify-center items-center text-new-black   text-xl" @click="additionalInput = additionalInput + 4">+</div>
  </div>
</div>
</template>

<script>
// SVG
import kurseSVG from '../assets/svg/kurse.vue';
import adjustmentSVG from '../assets/svg/adjustment.vue';
import listSVG from '../assets/svg/list.vue';
import faecherSVG from '../assets/svg/faecher.vue';
import tagSVG from '../assets/svg/tag.vue';
import timeSVG from '../assets/svg/time.vue';
import chatSVG from '../assets/svg/chat.vue';
import textSVG from '../assets/svg/text.vue';
import hatSVG from '../assets/svg/hat.vue';
import chevronSVG from '../assets/svg/chevron.vue';
import replySVG from '../assets/svg/reply.vue';
import examSVG from '../assets/svg/exam.vue';
import usersSVG from '../assets/svg/users.vue';
import lightningSVG from '../assets/svg/lightning.vue';

// Mixins
import iosVibrations from '../mixins/iosVibrations.vue';

export default {
  name: 'setting',
  props: ['label', 'additionalText', 'showicon', 'icon', 'border', 'action', 'truncate', 'storeID', 'additionalAcion', 'addActionLabel', 'addActionDefault', 'addActionStoreID', 'storeContainer', 'storeWrapper', 'deactivated', 'greyed', 'loadedValue'],
  mixins: [iosVibrations],
  components: {
    kurseSVG, faecherSVG, tagSVG, timeSVG, chatSVG, textSVG, hatSVG, chevronSVG, replySVG, examSVG, listSVG, adjustmentSVG, usersSVG, lightningSVG,
  },

  data() {
    return {
      additionalInput: 0,
    };
  },

  computed: {
    switched() {
      return this.$store.state[this.storeContainer][this.storeWrapper][this.storeID];
    },
    switchedAdditionalInput() {
      if (this.storeContainer && this.storeWrapper && this.addActionStoreID) return this.$store.state[this.storeContainer][this.storeWrapper][this.addActionStoreID];
      return null;
    },
  },

  watch: {
    additionalInput() {
      if (this.additionalInput < 0) {
        this.additionalInput = 0;
      }
      this.$store.commit('addToggle', {
        storeID: this.addActionStoreID, storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, value: this.additionalInput,
      });
    },
  },

  mounted() {
    if (this.switchedAdditionalInput !== null) {
      this.additionalInput = parseInt(this.switchedAdditionalInput, 0);
    } else {
      this.additionalInput = parseInt(this.addActionDefault, 0);
    }
  },

  methods: {
    switchToggle() {
      if (!this.deactivated) {
        this.iosVibrate(1);
        this.storeToggle(!this.switched);
      }
    },

    storeToggle(value) {
      this.$store.commit('addToggle', {
        storeID: this.storeID, storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, value,
      });
      if (this.additionalAcion) {
        this.$store.commit('addToggle', {
          storeID: this.addActionStoreID, storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, value: this.additionalInput,
        });
      }
      // Special Cases
      if (this.storeID === 'limitedQuestions' && !value) {
        this.$store.commit('addToggle', {
          storeID: 'limitFilter', storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, value: { limit: 60 },
        });
        this.$store.commit('addToggle', {
          storeID: 'questions', storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, value: 20,
        });
      }
      if (this.storeID === 'exam' && value) {
        this.$store.commit('addToggle', {
          storeID: 'comments', storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, value: false,
        });
      }
      if (this.storeID === 'comments' && value) {
        this.$store.commit('addToggle', {
          storeID: 'exam', storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, value: false,
        });
      }
    },
  },
};
</script>
